<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="compliance"
      :search="search"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>Compliance</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template #item.active="{ item }">
        <v-checkbox
          :input-value="item.active"
          class="primary--text"
          disabled
        />
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-compliance
        v-if="dialog"
        :populate-with="complianceToEdit"
        @compliance-added="addCompliance"
        @compliance-edited="editCompliance"
        @close-dialog="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import ComplienceService from '@/services/compliance.service';
// import DeleteConfirmation from '../../../components/shared/DeleteConfirmation';

export default {
  name: 'Compliance',
  components: {
    'form-compliance': () => import('./Form'),
    // DeleteConfirmation,
  },
  data: () => ({
    headers: [
      {
        text: 'Ativo',
        align: 'start',
        sortable: false,
        value: 'active',
      },
      {
        text: 'Título',
        sortable: false,
        value: 'headline',
      },
      {
        text: 'Autor',
        sortable: false,
        value: 'author',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    compliance: [],
    search: '',
    dialog: false,
    complianceToEdit: {},
    // dialogDeleteConfirmation: false,
    // dialogDelete: true,
    // deleteItemID: null,
  }),

  async created() {
    await this.findAllCompliances();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.complianceToEdit = {};
    },
    editItem(item) {
      this.complianceToEdit = item;
      this.dialog = true;
    },

    async findAllCompliances() {
      this.compliance = await ComplienceService.findAll();
    },

    findComplianceIndex(id) {
      return this.compliance.findIndex(
        (listCompliance) => listCompliance.id === id,
      );
    },

    // prepareToDelete(item) {
    //   this.dialogDeleteConfirmation = true;
    //   this.deleteItemID = item.id;
    // },

    // async deleteItem() {
    //   try {
    //     this.dialogDeleteConfirmation = false;
    //     const itemId = this.deleteItemID;
    //     await ComplienceService.deleteOne(itemId);
    //     this.$toast.success('Compliance excluída com sucesso');
    //     const index = this.findComplianceIndex(this.deleteItemID);
    //     this.compliance.splice(index, 1);
    //   } catch (e) {
    //     this.$handleHttpError(e);
    //   }
    // },

    async addCompliance() {
      await this.findAllCompliances();
    },

    async editCompliance() {
      await this.findAllCompliances();
    },
  },
};
</script>
