import api from '@/services/api';

const url = 'compliance';

const findAll = () => api.get(url);

const create = (compliance) => api.post(url, compliance);

const update = (compliance) => api.put(url, compliance);

const deleteOne = (id) => api.delete(`${url}/${id}`);

export default {
  findAll, create, update, deleteOne,
};
